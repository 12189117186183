





























































































































































































// @ is an alias to /src
import { MutationPayload } from 'vuex';
import { getExhibitorById } from '@/api/exhibitors';
import { ISidebar } from '@/components/';
import EventHero from '@/partials/EventHero.vue';
import Sidebar from '@/components/sidebar.vue';
import store from '@/store';
import router from '@/router';
import useContext from '@/composition/context';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    Sidebar,
    EventHero,
  },
  data() {
    return {
      sidebar: {
        title: 'Exhibiting',
        isActive: false,
        items: [],
      } as ISidebar,
      event: 0,
      exhibitor: 0,
      profileUrl: '',
      sidebarFixed: false,
      pending: false,
    };
  },
  setup() {
    const { translations, contextExhibitor, contextEvent } = useContext();
    const exhibitorInitial = computed(
      () => contextExhibitor.value.name && contextExhibitor.value.name.charAt(0),
    );
    return { translations, contextExhibitor, exhibitorInitial, contextEvent };
  },
  created() {
    this.event = parseInt(this.$route.params.event, 10);
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);

    store.commit('setContextRole', 'exhibitor');
    this.initSidebar();

    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setLanguage' || mutation.type === 'setContextEvent') {
        this.initSidebar();
      }
    });

    getExhibitorById(this.exhibitor).then((response) => {
      store.commit('setContextExhibitor', response.data);
      if (this.contextExhibitor.status === 'pending') {
        this.pending = true;
        router.push(`/event/${this.event}/manage-exhibitor/${this.contextExhibitor.id}/pending`);
      }
    });
  },
  methods: {
    initSidebar() {
      if (this.contextEvent.settings) {
        const eventSettings = this.contextEvent.settings;

        const productsHidden = !eventSettings.visitor_menu.products.display;
        const sessionsHidden = !eventSettings.visitor_menu.calendar.display;

        this.sidebar = {
          title: this.translations.exhibitors.sidebar.title,
          isActive: false,
          items: [
            {
              route: `/event/${this.event}/manage-exhibitor/${this.exhibitor}`,
              label: this.translations.exhibitors.sidebar.home,
              icon: 'dashboard',
            },
            {
              route: `/event/${this.event}/manage-exhibitor/${this.exhibitor}/products`,
              label: this.translations.exhibitors.sidebar.products,
              icon: 'local_offer',
              hidden: productsHidden,
            },
            {
              label: this.translations.exhibitors.sidebar.interactions,
              route: `/event/${this.event}/manage-exhibitor/${this.exhibitor}/visitors`,
              icon: 'assignment_ind',
            },

            {
              label: this.translations.exhibitors.sidebar.sessions,
              route: `/event/${this.event}/manage-exhibitor/${this.exhibitor}/sessions`,
              icon: 'event',
              hidden: sessionsHidden,
            },
            {
              label: this.translations.exhibitors.sidebar.exports,
              route: `/event/${this.event}/manage-exhibitor/${this.exhibitor}/exports`,
              icon: 'file_download',
            },
            // {
            //   label: this.translations.exhibitors.sidebar.tickets,
            //   route: `/event/${this.event}/manage-exhibitor/${this.exhibitor}/tickets`,
            //   icon: 'confirmation_number',
            // },
            {
              route: `/event/${this.event}/manage-exhibitor/${this.exhibitor}/profile`,
              label: this.translations.exhibitors.sidebar.settings,
              icon: 'content_paste',
            },
          ],
        };
      }
    },
  },
});
