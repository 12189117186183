



















import { defineComponent, PropType, reactive } from '@vue/composition-api';
import { Route } from 'vue-router';
import { IEventV2 } from '@/models/event';
import urlExists from '@/utils/urlExists';
import useContext, { IUseContext } from '@/composition/context';
import router from '@/router';
import SessionCard from '@/partials/SessionCard.vue';
// @ is an alias to /src

export default defineComponent({
  components: { SessionCard },
  props: {
    config: {
      type: Object as PropType<IEventV2>,
      required: true,
    },
  },
  setup(props) {
    const { config } = props;

    const {
      translations,
      contextSession,
      contextEmbedUrl,
      currentRoute,
      userIsManager,
      userIsExhibitor,
      userIsVisitor,
      userExhibitor,
      userVisitor,
      userAdmin,
    } = useContext() as unknown as IUseContext;

    const state = reactive({
      hidden: false,
      background: '',
      ready: false,
      routerIsVisitor: false,
      routeIsVisitor: false,
      outsideSessions: false,
      embedUrl: '',
      showCloseButton: false,
    });

    let previousSessionRoute = '';

    const setHeroImages = (event = config) => {
      if (event.hero_image) {
        urlExists(
          event.hero_image,
          () => {
            state.background = event.hero_image ? event.hero_image : '';
            state.ready = true;
          },
          () => {
            console.log('image failed');
          },
        );
      }
    };

    const validateRoute = (currentRoute: Route) => {
      const { event, session } = router.currentRoute.params;
      state.routeIsVisitor = !currentRoute.path.includes(`/event/${event}/manage-exhibitor`);

      state.showCloseButton = currentRoute.path !== `/event/${event}/session/${session}`;

      state.outsideSessions =
        !currentRoute.path.includes('/session/') &&
        !currentRoute.path.includes('sessions/session/');

      if (!state.outsideSessions) {
        previousSessionRoute = currentRoute.path;
      }
      if (currentRoute.name !== 'ExhibitorOnboarding' && currentRoute.name !== 'EventHome') {
        state.hidden = true;
      } else {
        state.hidden = false;
      }
    };

    setHeroImages();
    validateRoute(currentRoute.value);

    return {
      state,
      translations,
      contextSession,
      contextEmbedUrl,
      validateRoute,
      setHeroImages,
      previousSessionRoute,
      userIsManager,
      userIsExhibitor,
      userIsVisitor,
      userExhibitor,
      userVisitor,
      userAdmin,
      redirectUrl: process.env.VUE_APP_REDIRECT_URL,
    };
  },

  watch: {
    $route(prev: Route, next: Route) {
      if (next.path === router.currentRoute.path) {
        this.validateRoute(next);
      } else {
        this.validateRoute(prev);
      }
    },
    config(prev, next) {
      this.setHeroImages(next);
    },
  },
});
